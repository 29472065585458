<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getBrandList"
      :on-delete="handleDelete"
      :on-put-back="handlePutBack"
    >
      <template v-slot:top>
        <el-button
          class="top-btn"
          type="primary"
          size="small"
          @click="handleAddBrand"
          >新增</el-button
        >
      </template>
      <template
        v-if="filterForm.tab !== 'checking'"
        #item_donation_status_text="{ row }"
      >
        <list-status-text :text="row.donation_status_text" />
      </template>
      <template v-slot:filter>
        <list-filter
          :filterForm="filterForm"
          :pidList="[]"
          :uploadFilter="ok"
        />
      </template>

      <!-- 发起机构 -->
      <template slot="item_start_institution" slot-scope="{ row }">
        <template v-if="row.start_institution.length > 1">
          <span v-for="(item, i) in row.start_institution"
            >{{ item.name }}
            {{ i === row.start_institution.length - 1 ? "" : "、" }}</span
          >
        </template>
        <!-- 只有一个机构的时候不显示丶 -->
        <template v-else-if="row.start_institution.length > 0">
          <span>{{ row.start_institution[0].name }}</span>
        </template>
        <span v-else> -- </span>
      </template>
      <!-- 支持机构 -->
      <template slot="item_sponsor_institution" slot-scope="{ row }">
        <template v-if="row.sponsor_institution.length > 1">
          <span v-for="(item, i) in row.sponsor_institution"
            >{{ item.name }}
            {{ i === row.sponsor_institution.length - 1 ? "" : "、" }}</span
          >
        </template>
        <!-- 只有一个机构的时候不显示丶 -->
        <template v-else-if="row.sponsor_institution.length > 0">
          <span>{{ row.sponsor_institution[0] }}</span>
        </template>
        <span v-else> -- </span>
      </template>
      <template slot="item_price" slot-scope="{ row }">
        <span>￥{{ row.raised }}</span
        >&nbsp;/&nbsp;<span>￥{{ row.target_price }}</span>
      </template>
      <template slot="item_user_count" slot-scope="{ row }">
        <span>{{ row.user_count }}人次</span>
      </template>
      <template slot="item_company_count" slot-scope="{ row }">
        <span>{{ row.company_count }}家</span>
      </template>

      <template v-slot:tabletop>
        <el-button
          v-if="filterForm.tab === 'normal'"
          type="danger"
          size="small"
          @click="deletedArr"
          >删除</el-button
        >
      </template>
      <template v-slot:operation="scope">
        <el-button
          size="small"
          v-if="filterForm.tab === 'normal'"
          @click="checkRow(scope.row)"
          type="text"
          >查看</el-button
        >
        <!-- <el-button size="small" @click="editRow(scope.row)" type="text">数据统计</el-button> -->
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListStatusText from "@/base/components/List/ListStatusText";
import ListLayout from "@/base/layout/ListLayout";
import listFilter from "../../components/project/ListFilter";
import { donationList, softDelete, Pdelete, putBacks } from "../../api/project";
export default {
  components: { listFilter, ListLayout, ListStatusText },
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      thead: [
        { type: "selection" },
        { label: "项目名称", prop: "name", minWidth: 100 },
        { label: "项目时间", prop: "donation_time", minWidth: 300 },
        {
          label: "项目状态",
          prop: "donation_status_text",
          type: "slot",
          minWidth: 120,
        },
        {
          label: "发起机构",
          prop: "start_institution",
          minWidth: 120,
          type: "slot",
        },
        {
          label: "支持机构",
          type: "slot",

          prop: "sponsor_institution",
          minWidth: 120,
        },
        {
          label: "募捐编号",
          prop: "donation_number",
          minWidth: 120,
        },
        {
          label: "已筹金额/目标金额",
          prop: "price",
          type: "slot",
          minWidth: 200,
        },
        {
          label: "参与人次",
          prop: "user_count",
          type: "slot",
          minWidth: 200,
        },
        {
          label: "爱心企业",
          prop: "company_count",
          type: "slot",
          minWidth: 200,
        },
        { label: "操作", type: "operation", width: 100 },
      ],
      // 筛选对象
      filterForm: {
        name: "",
        status: -1,
        donation_number: "",
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        tab: "normal", //当前筛选tab
        page_size: 15, //每页多少条数据
        donation_status: -1, // 项目状态
      },
    };
  },
  methods: {
    getBrandList(data) {
      return donationList(data);
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.$refs.listLayout.getList(this.filterForm);
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList();
    },
    handleAddBrand() {
      this.$router.push({
        name: "addProject",
        params: {
          id: 0,
        },
      });
    },
    checkRow(row) {
      this.$router.push({
        name: "EditProject",
        params: {
          id: row.id,
        },
      });
    },
    // 多删
    deletedArr() {
      const selectArr = this.$refs.listLayout.selectArr;
      if (selectArr.length > 0) {
        this.$msgbox
          .confirm("确定是否删除", "提示", {
            type: "error",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
          .then((res) => {
            softDelete({ id: selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.refreshList();
              })
              .catch((err) => {});
          })
          .catch((err) => console.log(err));
      } else {
        this.$message.info("没有检测到勾选数据");
      }
    },
    // 还原数据
    handlePutBack(data) {
      return putBacks({ id: data });
    },
    // 数据删除
    handleDelete(idList, forever = false) {
      return forever ? Pdelete({ id: idList }) : softDelete({ id: idList });
    },
  },

  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
};
</script>

<style lang="scss" scoped></style>
