var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-page"},[_c('list-layout',{ref:"listLayout",attrs:{"filter-form":_vm.filterForm,"thead":_vm.thead,"tab-arr":_vm.tabArr,"on-fetch":_vm.getBrandList,"on-delete":_vm.handleDelete,"on-put-back":_vm.handlePutBack},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('el-button',{staticClass:"top-btn",attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleAddBrand}},[_vm._v("新增")])]},proxy:true},(_vm.filterForm.tab !== 'checking')?{key:"item_donation_status_text",fn:function(ref){
var row = ref.row;
return [_c('list-status-text',{attrs:{"text":row.donation_status_text}})]}}:null,{key:"filter",fn:function(){return [_c('list-filter',{attrs:{"filterForm":_vm.filterForm,"pidList":[],"uploadFilter":_vm.ok}})]},proxy:true},{key:"item_start_institution",fn:function(ref){
var row = ref.row;
return [(row.start_institution.length > 1)?_vm._l((row.start_institution),function(item,i){return _c('span',[_vm._v(_vm._s(item.name)+" "+_vm._s(i === row.start_institution.length - 1 ? "" : "、"))])}):(row.start_institution.length > 0)?[_c('span',[_vm._v(_vm._s(row.start_institution[0].name))])]:_c('span',[_vm._v(" -- ")])]}},{key:"item_sponsor_institution",fn:function(ref){
var row = ref.row;
return [(row.sponsor_institution.length > 1)?_vm._l((row.sponsor_institution),function(item,i){return _c('span',[_vm._v(_vm._s(item.name)+" "+_vm._s(i === row.sponsor_institution.length - 1 ? "" : "、"))])}):(row.sponsor_institution.length > 0)?[_c('span',[_vm._v(_vm._s(row.sponsor_institution[0]))])]:_c('span',[_vm._v(" -- ")])]}},{key:"item_price",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v("￥"+_vm._s(row.raised))]),_vm._v(" / "),_c('span',[_vm._v("￥"+_vm._s(row.target_price))])]}},{key:"item_user_count",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.user_count)+"人次")])]}},{key:"item_company_count",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.company_count)+"家")])]}},{key:"tabletop",fn:function(){return [(_vm.filterForm.tab === 'normal')?_c('el-button',{attrs:{"type":"danger","size":"small"},on:{"click":_vm.deletedArr}},[_vm._v("删除")]):_vm._e()]},proxy:true},{key:"operation",fn:function(scope){return [(_vm.filterForm.tab === 'normal')?_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.checkRow(scope.row)}}},[_vm._v("查看")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }